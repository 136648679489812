<ng-template #choicesiterator let-choices>
    <div class="row" *ngIf="choices">
        <ng-container *ngFor="let k of objectKeys(choices)">
            <ng-container *ngIf="choices[k].choices">
                <div (click)="checkGroup(choices[k].choices)" class="option label">
                    <input type="checkbox" [checked]="isSelectedGroup(choices[k].choices)" *ngIf="multiple" />
                    <i class="fa {{ choices[k].label }}"></i>
                </div>
                <div class="row">
                    <ng-template [ngTemplateOutlet]="choicesiterator" [ngTemplateOutletContext]="{ $implicit: choices[k].choices }"> </ng-template>
                </div>
            </ng-container>

            <ng-container *ngIf="!choices[k].choices">
                <div
                    (click)="checkValue(choices[k].value)"
                    class="col-6 col-sm-4 col-md-3 col-lg-2 option choice"
                    [class.checked]="isSelected(choices[k].value)"
                    [class.errors]="view.vars.errors.length"
                >
                    <div class="inner">
                        <img [src]="choices[k].value?.icon?.path" *ngIf="choices[k].value?.icon?.path" />
                        <h5 class="font-weight-bold" *ngIf="!choices[k].value?.icon?.path">{{ choices[k].label }}</h5>
                    </div>
                    <h5 class="font-weight-bold my-2" *ngIf="choices[k].value?.icon?.path">{{ choices[k].label }}</h5>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template [ngTemplateOutlet]="choicesiterator" [ngTemplateOutletContext]="{ $implicit: choices }"> </ng-template>
