import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Involved_default } from '../../../../../../api_types';

@Component({
    selector: 'app-involved-summary',
    templateUrl: './involved-summary.component.html',
    styleUrls: ['./involved-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvolvedSummaryComponent {
    @Input() involved: Involved_default | any;

    public choices = [
        { choice: 'age0to12', label: '0 - 12' },
        { choice: 'age13to17', label: '13 - 14' },
        { choice: 'age18to25', label: '18 - 25' },
        { choice: 'age26to45', label: '26 - 45' },
        { choice: 'age46plus', label: '46+' },
    ];

    constructor() {}
}
