<div class="app-wrapper top-bar">
    <main>
        <div class="container-fluid px-2 px-md-4 px-lg-5 py-4 py-xl-5">
            <div class="content-container py-5">
                <ul class="nav row">
                    <li class="nav-item col-12 col-md-6 pb-3">
                        <a class="nav-link" routerLink="/macyouth" routerLinkActive="active">
                            <img src="assets/macyouth_logo.svg" />
                        </a>
                    </li>

                    <li class="nav-item col-12 col-md-6 pb-3">
                        <a class="nav-link" routerLink="/macsafe" routerLinkActive="active">
                            <img src="assets/macsafe_logo.svg" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</div>
