import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgControl, Validator } from '@angular/forms';
import { Involved_default } from '../../../../../../api_types';
import { NgbCarousel, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalsServiceInterface, SfFormCollectionComponent } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-action-entry-simple',
    templateUrl: './sf-form-action-entry-simple.component.html',
    styleUrls: ['./sf-form-action-entry-simple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormActionEntrySimpleComponent extends SfFormCollectionComponent implements Validator, OnChanges, OnInit {
    @Input() involved?: Involved_default;

    private modalRef: NgbModalRef;
    public newAction: any;
    public sliding: boolean = false;

    public editingAction?: boolean;

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl, @Inject('ModalsService') public modalService: ModalsServiceInterface, private ngbModal: NgbModal) {
        super(cdRef, ngControl, modalService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    public open(content, id: number = null): void {
        if (id === null) {
            this.newAction = {
                action: null,
                involved: this.getInvolved(),
            };
        } else {
            this.newAction = JSON.parse(JSON.stringify(this.inputValue[id]));
        }

        this.modalRef = this.ngbModal.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'xl',
            scrollable: true,
            backdrop: 'static',
        });

        this.modalRef.closed.subscribe(() => {
            if (id === null) {
                this.inputValue.push(this.newAction);
            } else {
                this.inputValue[id] = this.newAction;
            }

            this.onInputChange();
            this.cdRef.detectChanges();
        });
    }

    getInvolved(): any {
        if (!this.involved) return {};
        if (!this.inputValue.length) return JSON.parse(JSON.stringify(this.involved));

        const value = JSON.parse(JSON.stringify(this.involved));
        this.inputValue
            .map(i => i.involved)
            .forEach(n => {
                Object.keys(n).forEach(j => {
                    value[j] = value[j] - n[j] >= 0 ? value[j] - n[j] : 0;
                });
            });

        return value;
    }

    selectAction(event: any, carousel: NgbCarousel): void {
        //   if (!this.newAction.action) return;
        //   carousel.next();
    }

    saveAction(): void {
        this.modalRef.close('saved');
    }
}
