import { ParticipantFormComponent } from '../modules/macyouth/components/participant-form/participant-form.component';
import { EngagementFormComponent } from '../modules/macsafe/components/forms/engagement-form/engagement-form.component';
import { ConsentFormComponent } from '../modules/macyouth/components/consent-form/consent-form.component';
import { UserFormComponent } from '../modules/_shared/components/forms/custom-forms/user-form/user-form.component';
import { DefaultFilterComponent, DefaultFormComponent, FormOptions } from '@hutsix/ngxh6';
import { ContactFormComponent } from '../modules/macyouth/components/contact-form/contact-form.component';
import forms_conf from '../_generated/ext_config/forms.json';

export const custom_filters: { [key: string]: any } = {
  DefaultFilterComponent: DefaultFilterComponent,
};

export const custom_forms: { [key: string]: any } = {
  DefaultFormEmbedComponent: DefaultFormComponent,
  ParticipantFormComponent: ParticipantFormComponent,
  EngagementFormComponent: EngagementFormComponent,
  ConsentFormComponent: ConsentFormComponent,
  UserFormComponent: UserFormComponent,
  ContactFormComponent: ContactFormComponent,
};

/** Override generated form values here */
const forms_conf_overrides: Override = {
  user: {
    object_name: 'User',
    object_class: 'App\\Entity\\User',
    api_get: '/api/user/form',
    api_delete: 'user/lock',
    api_refresh_endpoint: ['/api/v1/users', '/api/v1/macsafe/users', '/api/v1/macyouth/users'],
    allow_edits: true,
    allow_delete: false,
    allow_archive: true,
    success_route: 'users',
    delete_label: 'Lock User',
    delete_success_string: 'User Account has been archived.',
    component: 'UserFormComponent',
  },
  contact: {
    object_name: 'Contact',
    object_class: 'App\\Entity\\Contact',
    api_get: '/api/contact/form',
    offline_create: true,
    offline_edit: true,
    allow_edits: true,
    allow_archive: true,
    success_route: 'contacts',
    offline_priority: 500,
    component: 'ContactFormComponent',
  },
  staff_detail: {
    object_name: 'Staff Detail',
    api_get: '/api/staff_detail/form',
    allow_edits: true,
    allow_delete: false,
    success_route: null,
  },
  diversion: {
    object_name: 'Diversion',
    api_get: '/api/diversion/form',
    allow_edits: true,
    allow_delete: false,
    offline_edit: true,
    success_route: null,
  },
  attendance: {
    object_name: 'Attendance',
    api_get: '/api/attendance/form',
    api_delete: '/api/attendance/delete',
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    allow_edits: true,
    allow_delete: true,
    success_route: null,
    keyboard_shortcut: false,
    component: 'ParticipantFormComponent',
  },
  attendance_staff: {
    object_name: 'Staff Attendance',
    api_get: '/api/attendance-staff/form',
    api_delete: '/api/attendance-staff/delete',
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    allow_edits: true,
    allow_delete: true,
    keyboard_shortcut: false,
    success_route: null,
    component: 'ParticipantFormComponent',
  },
  case_management: {
    object_name: 'Case Management',
    api_get: '/api/case_management/form',
    allow_edits: true,
    allow_delete: false,
    success_route: null,
    modal_display: 'vertical',
    display: 'vertical',
  },
  notes: {
    object_name: 'Note',
    api_get: '/api/notes/form',
    api_delete: '/api/notes/archive',
    offline_create: true,
    offline_edit: true,
    // offline_endpoint: '/api/v1/note_lists',
    allow_edits: true,
    allow_delete: false,
    success_route: null,
    delete_label: 'Delete',
    delete_success_string: 'Note has been deleted.',
  },
  story: {
    object_name: 'Story',
    api_get: '/api/story/form',
    api_delete: '/api/story/archive',
    offline_create: true,
    offline_edit: true,
    allow_edits: true,
    allow_archive: true,
    success_route: null,
    delete_label: 'Delete',
    delete_success_string: 'Story has been deleted.',
    display: 'vertical',
  },
  user_profile: {
    object_name: 'Profile',
    route: 'profile',
    // autosave: true, // not implemented, TODO
    api_get: '/api/user/profile',
    create_string: 'Edit Profile',
    success_route: 'profile',
  },
  activity_spec: {
    object_name: 'Activity Type',
    object_class: 'App\\Entity\\MacYouth\\ActivitySpec',
    api_get: '/api/activity_spec/form',
    api_delete: '/api/activity_spec/lock',
    allow_edits: true,
    success_route: 'activity_specs',
    display: 'horizontal',
    modal_display: 'horizontal',
  },
  funding_source: {
    object_name: 'Funding Source',
    api_get: '/api/funding/source/form',
    api_delete: '/api/funding/source/lock',
    allow_edits: true,
    allow_delete: false,
    success_route: 'funding_sources',
    delete_label: 'Lock Funding Source',
    delete_success_string: 'Funding Source has been Locked.',
    modal_display: 'vertical',
  },
  facility: {
    object_name: 'Facility',
    api_get: '/api/facility/form',
    allow_edits: true,
    allow_delete: false,
    success_route: 'facilities',
    modal_display: 'vertical',
  },
  permission_spec: {
    object_name: 'Form',
    api_get: '/api/permission_spec/form',
    api_delete: '/api/permission_spec/lock',
    allow_edits: true,
    success_route: 'permission_specs',
    modal_display: 'vertical',
  },
  permission: {
    object_name: 'Form',
    api_get: '/api/permission/form',
    api_delete: '/api/permission/lock',
    allow_edits: true,
    success_route: 'permissions',
    modal_display: 'vertical',
  },
  attachment: {
    object_name: 'Attachment',
    api_get: '/api/attachment/form',
    allow_edits: true,
    success_route: 'attachments',
    modal_display: 'vertical',
  },
  community: {
    object_name: 'Community',
    api_get: '/api/community/form',
    allow_edits: true,
    allow_delete: false,
    success_route: 'communities',
    modal_display: 'vertical',
  },
  case_management_issue: {
    object_name: 'Case Management Issue',
    api_get: '/api/case_management_issue/form',
    api_delete: '/api/case_management_issue/archive',
    allow_edits: true,
    allow_delete: false,
    success_route: 'case_management_issues',
    modal_display: 'vertical',
  },
  activity: {
    object_name: 'Activity',
    object_class: 'App\\Entity\\MacYouth\\Activity',
    api_get: '/api/activity/form',
    allow_edits: true,
    allow_archive: true,
    offline_edit: true,
    offline_readonly: true,
    success_route: 'activities',
    modal_display: 'vertical',
    display: 'vertical',
  },
  case_management_activity: {
    object_name: 'Case Activity',
    api_get: '/api/case_activity/form',
    allow_edits: true,
    allow_delete: true,
    success_route: 'activities',
    modal_display: 'vertical',
  },
  activity_duplicate: {
    object_name: 'Duplicate Activity',
    api_get: '/api/activity/duplicate/form',
    allow_edits: true,
    success_route: 'activities',
  },
  consent_form: {
    object_name: 'Consent Form',
    api_get: '/api/consent_form/form',
    api_get_offline_edit: '/api/consent_form/form',
    allow_edits: true,
    allow_delete: false,
    offline_edit: true,
    offline_create: true,
    success_route: 'contacts',
    delete_success_string: '',
    component: 'ConsentFormComponent',
  },
  calendar_event: {
    object_class: 'App\\Entity\\MacYouth\\CalendarEvent',
    object_name: 'Event',
    allow_archive: true,
    display: 'horizontal-wide',
    modal_display: 'horizontal',
  },
  policy: {
    object_class: 'App\\Entity\\MacYouth\\Policy',
    object_name: 'Policy',
    allow_archive: true,
    modal_display: 'vertical',
  },
  tag: {
    object_class: 'App\\Entity\\MacYouth\\Tag',
    api_get: '/api/tag/form',
    object_name: 'Tag',
    allow_archive: true,
    modal_display: 'vertical',
  },
  policy_category: {
    object_class: 'App\\Entity\\MacYouth\\PolicyCategory',
    api_get: '/api/policy_category/form',
    api_delete: '/api/policy_category/lock',
    object_name: 'Policy Category',
    allow_archive: true,
    modal_display: 'vertical',
  },
  operations_report: {
    object_class: 'App\\Entity\\MacYouth\\OperationsReport',
    object_name: 'Operations Report',
    allow_archive: true,
    display: 'horizontal',
    success_route: '/macyouth/reports/operations/{id}',
    modal_display: 'horizontal',
    modal_size: 'lg',
  },
  agenda_item: {
    api_get: '/api/agenda_item/form',
    api_delete: '/api/agenda_item/delete',
  },
  minute: {
    api_get: '/api/minute/form',
    api_delete: '/api/minute/delete',
  },
  action_item: {
    api_get: '/api/action_item/form',
  },
  board_meeting: {
    api_get: '/api/board_meeting/form',
  },

  // MacSafe forms:
  action: {
    object_name: 'Action',
    object_class: 'App\\Entity\\MacSafe\\Action',
    api_get: '/api/macsafe/action/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'macsafe/config',
    modal_size: 'md',
    modal_display: 'vertical',
  },
  reason: {
    object_name: 'Engagement Reason',
    object_class: 'App\\Entity\\MacSafe\\Reason',
    api_get: '/api/macsafe/reason/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'macsafe/config',
    modal_size: 'md',
    modal_display: 'vertical',
  },
  contribution: {
    object_name: 'Contributing Factor',
    object_class: 'App\\Entity\\MacSafe\\Contribution',
    api_get: '/api/macsafe/contribution/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'macsafe/config',
    modal_size: 'md',
  },
  service_type: {
    object_name: 'Service Type',
    object_class: 'App\\Entity\\MacSafe\\ServiceType',
    api_get: '/api/macsafe/servicetype/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'macsafe/config',
    modal_size: 'md',
    modal_display: 'vertical',
  },
  engagement: {
    object_name: 'Engagement',
    object_class: 'App\\Entity\\MacSafe\\Engagement',
    api_get: '/api/macsafe/engagement/form',
    allow_edits: true,
    allow_archive: true,
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    offline_endpoint: '/api/v1/macsafe/engagements',
    success_route: 'macsafe/engagements',
    create_string: 'Log Engagement',
    edit_string: 'Log Engagement',
    edit_success_string: 'Engagement Saved',
    create_success_string: 'Engagement Saved',
    component: 'EngagementFormComponent',
  },
  service_delivery: {
    object_name: 'Service Delivery',
    object_class: 'App\\Entity\\MacSafe\\ServiceDelivery',
    api_get: '/api/macsafe/service_delivery/form',
    allow_edits: true,
    allow_archive: true,
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    offline_endpoint: '/api/v1/macsafe/service_deliveries',
    success_route: 'macsafe/service-deliveries',
    display: 'vertical',
  },
};

/** Create custom forms or override generated forms here */
const forms = {
  // example_test: new FormOptions({
  //   slug: 'profile',
  //   object_name: 'Example',
  //   display: 'horizontal-wide',
  //   create_string: 'Profile',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getFormsConfig(): { [key: string]: FormOptions } {
  const f = JSON.parse(JSON.stringify(forms_conf));
  for (const [k, v] of Object.entries(forms_conf_overrides)) {
    if (f[k]) {
      f[k] = Object.assign(forms_conf[k], v);
    } else {
      console.warn(`Attempt to override generated form failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...f, ...forms };
}

export function getForm(slug: FormKeys): FormOptions {
  return getFormsConfig()[slug];
}

declare global {
  type FormKeys = keyof typeof forms | keyof typeof forms_conf;
}

type Override = { [Property in keyof Partial<typeof forms_conf>]: Partial<FormOptions> };
