<ng-template #choicesiterator let-choices>
    <ng-container *ngFor="let k of objectKeys(choices)">
        <ng-container *ngIf="choices[k].choices">
            <div (click)="checkGroup(choices[k].choices)" class="option label">
                <input type="checkbox" [checked]="isSelectedGroup(choices[k].choices)" *ngIf="multiple" />
                <i class="fa {{ choices[k].label }}"></i>
            </div>
            <div class="choice-group">
                <ng-template [ngTemplateOutlet]="choicesiterator" [ngTemplateOutletContext]="{ $implicit: choices[k].choices }"> </ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="!choices[k].choices">
            <div (click)="checkValue(choices[k].value)" class="option choice" [class.errors]="view?.vars?.errors.length">
                <input [type]="multiple ? 'checkbox' : 'radio'" [checked]="isSelected(choices[k].value)" />
                <i style="font-size: 2rem" class="fa {{ choices[k].label }}"></i>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template [ngTemplateOutlet]="choicesiterator" [ngTemplateOutletContext]="{ $implicit: choices }"> </ng-template>
