<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <sf-form name="form" [view]="form.view" (ngModelChange)="onChange($event)" [(ngModel)]="form.model" [fields]="['photo']"></sf-form>

        <ngxh6-tabs
            class="mt-5"
            [splitIndex]="4"
            [containerClass]="'tab-container p-4 mb-3'"
            [navItems]="navItems"
            [content]="tabContent"
            [key]="'form-tab'"
            [small]="true"
        ></ngxh6-tabs>

        <ng-template #tabContent let-id="id">
            <ng-container *ngIf="id === 'basic'">
                <fieldset ngModelGroup="basic" #basicForm="ngModelGroup">
                    <sf-form
                        name="form1"
                        [view]="form.view"
                        (ngModelChange)="onChange($event)"
                        [(ngModel)]="form.model"
                        [fields]="['firstName', 'lastName', 'communities', 'languageGroups', 'gender', 'dob', 'aboriginal', 'address']"
                    ></sf-form>
                </fieldset>
            </ng-container>

            <ng-container *ngIf="id === 'staff'">
                <sf-form #staffForm name="form2" [view]="form.view" (ngModelChange)="onChange($event)" [(ngModel)]="form.model" [fields]="['staffDetail']"></sf-form>
            </ng-container>

            <ng-container *ngIf="id === 'account'">
                <div class="row">
                    <div class="col-6">
                        <sf-form
                            #accountForm
                            name="form3"
                            rowClasses="col-12"
                            [view]="form.view"
                            (ngModelChange)="onChange($event)"
                            [(ngModel)]="form.model"
                            [fields]="['email', 'username', 'roles', 'locked']"
                        ></sf-form>
                    </div>
                    <div class="col-6">
                        <sf-form
                            #accountForm
                            name="form4"
                            rowClasses="col-12"
                            [view]="form.view"
                            (ngModelChange)="onChange($event)"
                            [(ngModel)]="form.model"
                            [fields]="['plainPassword']"
                        ></sf-form>
                    </div>
                </div>
            </ng-container>
        </ng-template>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            (cancel)="cancel()"
            (delete)="delete()"
            (archive)="archive($event)"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError>
    <ng-container *ngIf="form.error; else loading">
        <ngxh6-api-error [error]="form.error"></ngxh6-api-error>

        <div class="d-flex actions" [class.sticky]="options.sticky_actions">
            <input type="button" value="Back" (click)="location.back()" class="btn btn-gray" *ngIf="options.show_back" [disabled]="form.saving" />
            <input type="button" value="Cancel" (click)="cancel()" class="btn btn-gray" *ngIf="options.show_cancel" [disabled]="form.saving" />
        </div>
    </ng-container>

    <ng-template #loading>
        <ngxh6-loading-spinner @fadeInOut></ngxh6-loading-spinner>
    </ng-template>
</ng-template>
