import { Injectable } from '@angular/core';
import { User_default_user_get_collection_user_get_item } from '../../api_types';
import { UserAccountInterface, UserService } from '@hutsix/ngxh6';

/**
 * Service for managing user data.
 */
@Injectable()
export class MacUserService extends UserService {
    public account?: MacUserAccount;

    set default_community_and_staff(value: any) {
        this.account.default_community_and_staff = value;
        this.saveUserData();
    }

    get default_community_and_staff(): any {
        return this.account?.default_community_and_staff;
    }

    get list_communities(): any {
        return this.user.communities.map(comm => comm.id);
    }
}

export class MacUserAccount implements UserAccountInterface {
    meta: any = {};
    token?: string;
    refresh_token?: string;
    offline_lock: boolean = false;
    user: User_default_user_get_collection_user_get_item;
    simulating_offline: boolean = false;
    impersonating?: string;
    email?: string;
    sync_date?: number;
    default_community_and_staff?: any = null;
}
