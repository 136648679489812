import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgControl, NgModel, Validator } from '@angular/forms';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { ValueAccessorBase } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-signature',
    templateUrl: './sf-form-signature.component.html',
    styleUrls: ['./sf-form-signature.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormSignatureComponent extends ValueAccessorBase implements Validator, OnChanges, OnInit {
    @ViewChild('formInput', { static: true }) formInput: NgModel;
    @ViewChild('signature')
    public signaturePad: SignaturePadComponent;

    public signaturePadOptions: NgSignaturePadOptions = {
        // passed through to szimek/signature_pad constructor
        minWidth: 5,
        canvasWidth: 350,
        canvasHeight: 300,
        backgroundColor: 'rgb(245,245,245)',
    };

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl) {
        super(cdRef, ngControl);
    }

    clear(): void {
        this.signaturePad.clear();
        this.inputValue = null;
        this.onInputChange();
    }

    drawComplete(event: MouseEvent | Touch): void {
        this.inputValue = JSON.stringify(this.signaturePad.toData());
        this.onInputChange();
    }

    transformValueModelToView(value: any): any {
        // draw on a timeout to ensure the signaturePad canvas is ready.
        setTimeout(() => {
            this.signaturePad.fromData(JSON.parse(this.inputValue));
        }, 100);

        return value;
    }
}
