import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MacUserService } from '../../../../services/user/mac-user.service';
import { Location } from '@angular/common';
import { fadeInOut2, fadeInOut } from '../../../../animations';
import { ApiServiceInterface, DefaultFormComponent, FormServiceInterface, ModalsServiceInterface } from '@hutsix/ngxh6';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-engagement-form',
    templateUrl: './consent-form.component.html',
    styleUrls: ['./consent-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentFormComponent extends DefaultFormComponent implements OnInit {
    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: MacUserService,
        public location: Location,
        public cdRef: ChangeDetectorRef,

        @Inject('ModalsService') public modals: ModalsServiceInterface,
        @Inject('ApiService') public api: ApiServiceInterface,
        public toastr: ToastrService,
    ) {
        super(formService, userService, location, cdRef);
    }

    ngOnInit() {
        super.ngOnInit();
        this.form.model.timestamp = new Date();
    }

    submit(auto?: boolean) {
        if (!this.api.offline.isConnected && !this.formRef.valid) {
            this.toastr.error('Form contains errors', 'Unable to save');
            return;
        }
        super.submit(auto);
    }
}
