<ng-container *ngIf="form.view; else loadingOrError">
    <ng-container *ngIf="userService.isGranted('ROLE_MACSAFE_COORDINATOR')">
        <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view" @fadeInOut>
            <div class="row">
                <sf-form
                    formClasses="col-md-6"
                    rowClasses="''"
                    name="community"
                    [view]="form.view"
                    [(ngModel)]="form.model"
                    (ngModelChange)="communityChanged($event)"
                    [fields]="['community']"
                ></sf-form>
                <sf-widget class="col-md-6" name="staff" [view]="form.view.children['staff']" [(ngModel)]="form.model['staff']" [meta]="staffSearch"></sf-widget>
                <sf-form formClasses="col-md-6" rowClasses="''" name="start" [view]="form.view" [(ngModel)]="form.model" [fields]="['start']"></sf-form>
                <sf-form formClasses="col-md-6" rowClasses="''" name="location" [view]="form.view" [(ngModel)]="form.model" [fields]="['location']"></sf-form>
            </div>

            <label>Who was involved?</label>

            <div class="card mb-4 pt-3 px-3">
                <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['involved']"></sf-form>
            </div>

            <ngxh6-tabs [splitIndex]="4" [containerClass]="'tab-container p-4 mb-3'" [navItems]="navItems" [content]="tabContent" [key]="'form-tab'" [small]="true"></ngxh6-tabs>

            <ng-template #tabContent let-id="id">
                <ng-container *ngIf="id === 'reasons'">
                    <sf-form name="form1" [view]="form.view" [(ngModel)]="form.model" [fields]="['reason']"></sf-form>
                </ng-container>
                <ng-container *ngIf="id === 'actions'">
                    <!--          <sf-form name='form2' [view]="form.view" [(ngModel)]="form.model" [fields]="['actions']"></sf-form>-->
                    <sf-form-action-entry
                        class="d-block mb-3"
                        name="actions"
                        [involved]="form.model['involved']"
                        [view]="form.view.children['actions']"
                        [(ngModel)]="form.model['actions']"
                    ></sf-form-action-entry>
                </ng-container>
                <!--        <ng-container *ngIf="id === 'contributions'">-->
                <!--          <sf-form name='form3' [view]="form.view" [(ngModel)]="form.model" [fields]="['contribution']"></sf-form>-->
                <!--        </ng-container>-->
                <ng-container *ngIf="id === 'comments'">
                    <sf-form name="form3" [view]="form.view" [(ngModel)]="form.model" [fields]="['comments']"></sf-form>
                </ng-container>
            </ng-template>

            <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['id', 'gpsLng', 'gpsLat']"></sf-form>

            <hr *ngIf="!options.sticky_actions && options.actions_separator" />
            <ngxh6-form-actions
                [form]="form"
                [options]="options"
                [formRef]="formRef"
                [valid]="formRef.valid"
                (cancel)="cancel()"
                (delete)="delete()"
                (archive)="archive($event)"
            ></ngxh6-form-actions>
        </form>
    </ng-container>

    <ng-container *ngIf="!userService.isGranted('ROLE_MACSAFE_COORDINATOR')">
        <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view" @fadeInOut [class.dim]="form.saving || sliding">
            <ngb-carousel
                #carousel
                (slide)="sliding = true"
                (slid)="sliding = false"
                [class.sliding]="sliding"
                [showNavigationArrows]="false"
                [showNavigationIndicators]="false"
                [wrap]="false"
                [interval]="0"
                [keyboard]="false"
            >
                <ng-template ngbSlide [id]="'slide-0'">
                    <h5 class="font-weight-bold text-center mb-4 mt-3">Step 1. Why are we needed?</h5>
                    <sf-form-select-imagegrid
                        class="d-block mb-3"
                        name="reason"
                        [view]="form.view.children['reason']"
                        [(ngModel)]="form.model['reason']"
                    ></sf-form-select-imagegrid>
                </ng-template>

                <ng-template ngbSlide [id]="'slide-1'">
                    <h5 class="font-weight-bold text-center mb-4 mt-3">Step 2. Who was involved?</h5>
                    <sf-form-involved class="d-block mb-3" name="involved" [view]="form.view.children['involved']" [(ngModel)]="form.model['involved']"></sf-form-involved>
                </ng-template>

                <ng-template ngbSlide [id]="'slide-2'">
                    <h5 class="font-weight-bold text-center mb-4 mt-3">Step 3. What did we do?</h5>
                    <sf-form-action-entry-simple
                        class="d-block mb-3"
                        name="actions"
                        [involved]="form.model['involved']"
                        [view]="form.view.children['actions']"
                        [(ngModel)]="form.model['actions']"
                    ></sf-form-action-entry-simple>
                </ng-template>

                <!--        <ng-template ngbSlide [id]="'slide-3'">-->
                <!--          <h5 class="font-weight-bold text-center mb-4 mt-3">Step 4. What contributed?</h5>-->
                <!--          <sf-form-select-imagegrid class="d-block mb-3" name='contribution' [view]="form.view.children['contribution']" [(ngModel)]="form.model['contribution']"></sf-form-select-imagegrid>-->
                <!--        </ng-template>-->

                <ng-template ngbSlide [id]="'slide-4'">
                    <h5 class="font-weight-bold text-center mb-4 mt-3">Step 4. Notes or comments</h5>
                    <sf-form-textarea name="form3" [view]="form.view.children['comments']" [(ngModel)]="form.model['comments']"></sf-form-textarea>

                    <hr />

                    <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['start']"></sf-form>
                </ng-template>
            </ngb-carousel>

            <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['id', 'gpsLng', 'gpsLat']"></sf-form>

            <hr *ngIf="!options.sticky_actions && options.actions_separator" />

            <div class="d-flex form-actions sticky py-2">
                <button type="button" class="btn btn-gray" (click)="cancel()" [disabled]="sliding || form.saving">Close</button>
                <div class="ms-auto">
                    <button type="button" class="btn btn-primary me-5" (click)="changeSlide('prev')" *ngIf="carousel.activeId !== 'slide-0'" [disabled]="sliding || form.saving">
                        <i class="fa fa-arrow-left me-2"></i>Back
                    </button>
                    <button type="button" class="btn btn-primary" (click)="changeSlide('next')" *ngIf="carousel.activeId !== 'slide-4'" [disabled]="sliding || form.saving">
                        Next<i class="fa fa-arrow-right ms-2"></i>
                    </button>
                    <button type="button" class="btn btn-primary" (click)="end()" *ngIf="carousel.activeId === 'slide-4'" [disabled]="sliding || form.saving">
                        <i class="fa fa-check me-2"></i>Done
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
</ng-container>

<ng-template #loadingOrError>
    <ng-container *ngIf="form.error; else loading">
        <ngxh6-api-error [error]="form.error"></ngxh6-api-error>

        <div class="d-flex actions" [class.sticky]="options.sticky_actions">
            <input type="button" value="Back" (click)="location.back()" class="btn btn-gray" *ngIf="options.show_back" [disabled]="form.saving" />
            <input type="button" value="Cancel" (click)="cancel()" class="btn btn-gray" *ngIf="options.show_cancel" [disabled]="form.saving" />
        </div>
    </ng-container>

    <ng-template #loading>
        <ngxh6-loading-spinner @fadeInOut></ngxh6-loading-spinner>
    </ng-template>
</ng-template>
