import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MacUserService } from '../../services/user/mac-user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    constructor(@Inject('UserService') public user: MacUserService, private router: Router) {}

    ngOnInit(): void {
        if (!this.user.isGranted('ROLE_MACYOUTH') || !this.user.isGranted('ROLE_MACSAFE')) {
            if (this.user.isGranted('ROLE_MACYOUTH')) this.router.navigate(['/macyouth']);
            if (this.user.isGranted('ROLE_MACSAFE')) this.router.navigate(['/macsafe']);
        }
    }
}
