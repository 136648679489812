<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view" @fadeInOut>
        <sf-form class="invisible position-absolute" name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['contact', 'timestamp']"></sf-form>
        <sf-form name="form2" [view]="form.view" [(ngModel)]="form.model" [fields]="['signatureName', 'updated']"></sf-form>
        In order to provide relevant services MacYouth need to collect and record personal information. All information is stored securely on a system that only MacYouth members
        can access.
        <p></p>
        <p>MacYouth take the confidentiality and security of your information seriously, and will only share your information with other parties in the following circumstances:</p>

        <ul>
            <li>If you give us permission to share information with another person or organisation;</li>
            <li>If we are worried about your child/youth’s safety or the safety of someone else;</li>
            <li>If we are required to disclose information by law, such as a court subpoena or mandatory report (Child Safety or Family and Domestic Violence)</li>
        </ul>
        If your personal information is to be used in any other way you will be asked for consent and have the right to refuse. If you have any concerns or questions about privacy,
        confidentiality or informed consent, please ask a MacYouth staff member.
        <p></p>
        <p></p>
        <h4>Terms</h4>
        <p></p>
        As legal guardian/responsible adult, you give permission for your child/youth to:

        <div class="terms">
            <ul>
                <p></p>
                <li><label>attend MacYouth activities in community between the hours of 6am-10pm</label></li>
                <li><label>attend day activities outside of community during daylight hours</label></li>
                <li><label>travel in MacYouth vehicles in line with Australian driving laws</label></li>
                <li><label>use computers and the internet during program</label></li>
                <li><label>in an emergency call 000 and help get them to care</label></li>
                <li><label>share information, without names for reporting purposes</label></li>
                <p></p>
                <sf-form name="form3" [view]="form.view" [(ngModel)]="form.model" [fields]="['photo', 'caseWork']"></sf-form>
            </ul>
        </div>
        MacYouth have a strict no violence, no bullying or disrespectful behaviour. If your child/youth behaves poorly MacYouth will ask them to leave and in serious cases follow
        up with responsible guardian.
        <p></p>

        <sf-form name="form4" [view]="form.view" [(ngModel)]="form.model" [fields]="['additionalNotes', 'signature']"></sf-form>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            (cancel)="cancel()"
            (delete)="delete()"
            (archive)="archive($event)"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError>
    <ng-container *ngIf="form.error; else loading">
        <ngxh6-api-error [error]="form.error"></ngxh6-api-error>

        <div class="d-flex actions" [class.sticky]="options.sticky_actions">
            <input type="button" value="Back" (click)="location.back()" class="btn btn-gray" *ngIf="options.show_back" [disabled]="form.saving" />
            <input type="button" value="Cancel" (click)="cancel()" class="btn btn-gray" *ngIf="options.show_cancel" [disabled]="form.saving" />
        </div>
    </ng-container>

    <ng-template #loading>
        <ngxh6-loading-spinner @fadeInOut></ngxh6-loading-spinner>
    </ng-template>
</ng-template>
