<div class="d-flex mb-2" *ngIf="involved">
    <ng-container *ngFor="let item of choices">
        <div class="d-flex flex-column mx-2 align-items-center" *ngIf="involved[item.choice + 'm']">
            <label>{{ item.label }} (M)</label>
            <h6 class="font-weight-bold">{{ involved[item.choice + 'm'] }}</h6>
        </div>
        <div class="d-flex flex-column mx-2 align-items-center" *ngIf="involved[item.choice + 'f']">
            <label>{{ item.label }} (F)</label>
            <h6 class="font-weight-bold">{{ involved[item.choice + 'f'] }}</h6>
        </div>
    </ng-container>
</div>
