import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SfFormSelectExpandedComponent } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-select-imagegrid',
    templateUrl: './sf-form-select-imagegrid.component.html',
    styleUrls: ['./sf-form-select-imagegrid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormSelectImagegridComponent extends SfFormSelectExpandedComponent implements OnInit {
    public objectKeys = Object.keys;

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl) {
        super(cdRef, ngControl);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
}
