<nav class="navbar navbar-expand-lg navbar-dark fixed-top d-print-none">
    <a class="navbar-brand py-1 ms-2" routerLink="/">
        <img src="/assets/macsafe_logo_inverted.svg" class="ms-3 p-1" alt="MacSafe" [class.d-block]="router.url.includes('/macsafe')" />
        <img src="/assets/macyouth_logo_inverted.svg" class="p-1 macyouth" alt="MacYouth" [class.d-block]="router.url.includes('/macyouth')" />
        <img
            src="/assets/macdonnell_logo_inverted.svg"
            class="mt-1"
            alt="MacDonnell Council"
            [class.d-block]="!router.url.includes('/macyouth') && !router.url.includes('/macsafe')"
        />
    </a>

    <ng-container *ngIf="userService.watch | async as _user">
        <div class="ms-auto" display="dynamic" placement="bottom-end" ngbDropdown *ngIf="_user.loggedIn">
            <button class="btn profile-button" ngbDropdownToggle (click)="update()">
                <ngxh6-usericon [user]="_user.account.user" [showTooltip]="false" class="me-0"></ngxh6-usericon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <ng-container *ngIf="userService.isGranted('ROLE_MACYOUTH') && userService.isGranted('ROLE_MACSAFE')">
                    <h6 class="dropdown-header">Departments</h6>

                    <button class="dropdown-item" routerLink="macyouth" *ngIf="userService.isGranted('ROLE_MACYOUTH')">MacYouth</button>
                    <button class="dropdown-item" routerLink="macsafe" *ngIf="userService.isGranted('ROLE_MACSAFE')">MacSafe</button>
                    <button class="dropdown-item" routerLink="user-admin">My Mac Council</button>

                    <div class="dropdown-divider"></div>
                </ng-container>

                <h6 class="dropdown-header">{{ _user.account.user.name }}</h6>

                <button class="dropdown-item" routerLink="user-admin"><i class="fa fa-fw fa-user me-2"></i> My Profile</button>

                <button class="dropdown-item" routerLink="user-admin/offline">
                    <i class="fa fa-fw fa-wifi me-2"></i> Manage Offline Data
                    <ng-container *ngIf="offlineQueue$ | async as _offline">
                        <span class="badge-pill badge bg-success" *ngIf="_offline.length">{{ _offline.length }}</span>
                    </ng-container>
                </button>

                <button class="dropdown-item warning" (click)="clearCaches()"><i class="fa fa-fw fa-trash me-2"></i> Clear Data Cache</button>

                <div class="dropdown-divider"></div>

                <button class="dropdown-item danger" (click)="userService.logOut()" *ngIf="!_user.account.impersonating"><i class="fa fa-fw fa-sign-out me-2"></i> Logout</button>

                <button class="dropdown-item danger" (click)="userService.exitImpersonation()" *ngIf="_user.account.impersonating">
                    <i class="fa fa-fw fa-sign-out me-2"></i> Exit Impersonation
                </button>

                <div class="dropdown-divider"></div>

                <div class="dropdown-item-text"><ngbd-darkmode-switch></ngbd-darkmode-switch></div>

                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">App Version: {{ version }}</h6>
                <h6 class="dropdown-header">Environment: {{ env }}</h6>
            </div>
        </div>
    </ng-container>
</nav>
