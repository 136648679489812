import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MacUserService } from '../../../../services/user/mac-user.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { fadeInOut2, fadeInOut } from '../../../../animations';
import { DefaultFormComponent, FormServiceInterface, ModalsServiceInterface } from '@hutsix/ngxh6';

@Component({
    selector: 'app-participant-form',
    templateUrl: './participant-form.component.html',
    styleUrls: ['./participant-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantFormComponent extends DefaultFormComponent {
    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: MacUserService,
        public location: Location,
        public cdRef: ChangeDetectorRef,
        @Inject('ModalsService') public modals: ModalsServiceInterface,
    ) {
        super(formService, userService, location, cdRef);
    }

    submit(): void {
        this.form
            .submit()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                res => {
                    this.submitted.emit(res);
                    this.cdRef.detectChanges();
                },
                err => {
                    this.cdRef.detectChanges();
                },
            );
    }
}
