<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view" @fadeInOut>
        <sf-form name="form" [view]="form.view" [(ngModel)]="form.model"></sf-form>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            (cancel)="cancel()"
            (delete)="delete()"
            (archive)="archive($event)"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError><ngxh6-loading-or-error [error]="form.error"></ngxh6-loading-or-error></ng-template>
