import { ApiResponse, ApiServiceInterface, SyncEndpoints } from '@hutsix/ngxh6';
import { concat, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reason_default } from '../api_types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MacUserService } from '../services/user/mac-user.service';
import { ActivityInterface } from '../_generated/api_interfaces/api/activity.interface';

export function syncConfigFactory(userService: MacUserService, apiService: ApiServiceInterface, http: HttpClient): object {
  return {
    /**
     * Add any endpoints that need to be synced offline here
     */
    endpoints: [],

    /**
     * Tasks are Observable<string[]>[] and will be executed in sequence. You can perform any actions you want here.
     * The observable should return string[] of endpoints that will be added to the offline sync queue.
     */
    tasks: [
      // Exclude Contacts for non MacYouth staff
      new Observable<string[] | SyncEndpoints>(observer => {
        const endpoints = ['/api/v1/communities?pagination=false', '/api/v1/users?pagination=false'];
        let exclusions = [];
        if (userService.isGranted('ROLE_MACSAFE')) {
          endpoints.push('/api/v1/macsafe/users?pagination=false');
          exclusions = ['/api/v1/contacts/all'];
        }
        if (userService.isGranted('ROLE_MACYOUTH')) {
          endpoints.push('/api/v1/macyouth/users?pagination=false');
          exclusions = ['/api/v1/macsafe/engagements', '/api/v1/macsafe/service_deliveries', 'macsafe/engagement/form', 'macsafe/service_delivery/form'];
        }

        observer.next(new SyncEndpoints({ exclusions, endpoints }));
        observer.complete();
      }),

      // Activities Sync
      new Observable<string[]>(observer => {
        if (!userService.isGranted('ROLE_MACYOUTH')) {
          observer.complete();
          return;
        }

        const query = { pagination: 0 };

        const endpoints = [];

        apiService.get({ url: '/api/v1/activities/my_activities', query, useCache: true, refresh: true, displayErrors: false }).subscribe(
          (res: ReturnType<ActivityInterface['getMyActivities']>) => {
            if (res.type !== 'api' || !res.data.length) return;

            res.data.forEach(i => {
              endpoints.push('/api/activity/form/' + i.id);
              endpoints.push('/api/v1/activities/' + i.id);
              endpoints.push('/api/v1/activities/' + i.id + '/attendances?pagination=false');
              endpoints.push('/api/v1/activities/' + i.id + '/attendance_staffs?pagination=false');
            });
          },
          () => {
            observer.complete();
          },
          () => {
            observer.next(endpoints);
            observer.complete();
          },
        );
      }),

      // Icon Sync
      new Observable<string[]>(observer => {
        if (!userService.isGranted('ROLE_MACSAFE')) {
          observer.complete();
          return;
        }

        const iconEndpoints = ['/api/v1/macsafe/reasons?pagination=false', '/api/v1/macsafe/actions?pagination=false'];

        const apis = iconEndpoints.map(iconEndpoint => {
          return new Observable<void>(obs => {
            apiService
              .get({ url: iconEndpoint, useCache: true, refresh: true, displayErrors: false })
              .pipe(
                map((res: ApiResponse<Reason_default[]>) => {
                  if (res.type !== 'api' || !res.data.length) return;

                  const imgs = [];

                  res.data.forEach(i => {
                    if (i?.icon?.path) {
                      const headers = new HttpHeaders({ accept: 'image/png' });
                      imgs.push(http.get(i.icon.path, { headers, responseType: 'blob' }));
                    }
                  });

                  concat(...imgs).subscribe(
                    () => {},
                    () => {
                      obs.complete();
                    },
                    () => {
                      obs.complete();
                    },
                  );
                }),
              )
              .subscribe();
          });
        });

        concat(...apis).subscribe(
          () => {},
          () => observer.complete(),
          () => observer.complete(),
        );
      }),
    ],
  };
}
