<div class="collection-container">
    <div *ngFor="let item of inputValue; let i = index; trackBy: trackByIndex" [@fadeIn]="!!item.__created" [class.dim]="fade === i" class="collection-rows">
        <form #itemForm="ngForm" ngForm [class.removable]="view.vars.allow_delete">
            <div class="d-flex mb-3 summary" (click)="open(content, i)">
                <div class="icon small me-3 flex-shrink-0" *ngIf="inputValue[i]?.action?.icon?.path">
                    <img [src]="inputValue[i].action.icon.path" />
                </div>
                <div class="d-flex flex-column">
                    <h5 class="font-weight-bold">{{ inputValue[i].action.name }}</h5>
                    <app-involved-summary [involved]="inputValue[i].involved"></app-involved-summary>
                </div>
            </div>

            <sf-form
                class="promis"
                name="promis"
                *ngIf="inputValue[i]?.action?.name?.toLowerCase() === 'called police' || inputValue[i]?.action?.name?.toLowerCase() === 'called ambulance'"
                [view]="view.children[i] || view.vars.prototype"
                [(ngModel)]="inputValue[i]"
                [renderAll]="true"
                (ngModelChange)="onInputChange()"
                [fields]="['promisNumber']"
            ></sf-form>

            <div class="collection-remove-wrapper" *ngIf="view.vars.allow_delete">
                <button type="button" class="btn btn-danger collection-remove" (click)="removeItem(i)" (mouseover)="fade = i" (mouseleave)="fade = null">
                    <i class="fa fa-trash"></i>
                </button>
            </div>

            <hr />
        </form>
    </div>

    <div class="d-block text-center" *ngIf="view.vars.allow_add">
        <button type="button" class="btn btn-lg btn-primary mt-1 mb-0" (click)="open(content)"><i class="fa fa-plus"></i> Add Action</button>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Action</h4>
    </div>
    <div class="modal-body">
        <ngb-carousel
            #actionCarousel
            (slide)="sliding = true"
            (slid)="sliding = false"
            [class.sliding]="sliding"
            [showNavigationArrows]="false"
            [showNavigationIndicators]="false"
            [wrap]="false"
            [interval]="0"
            [keyboard]="false"
        >
            <ng-template ngbSlide [id]="'action-slide-0'">
                <sf-form-select-imagegrid
                    class="d-block mb-3 mx-3"
                    name="action"
                    [view]="view.vars.prototype.children['action']"
                    [(ngModel)]="newAction.action"
                    (click)="selectAction($event, actionCarousel)"
                ></sf-form-select-imagegrid>
            </ng-template>

            <ng-template ngbSlide [id]="'action-slide-1'">
                <div class="d-flex flex-column align-items-center justify-content-center mb-4 mt-2" *ngIf="newAction?.action">
                    <div class="action">
                        <div class="icon mb-2" *ngIf="newAction?.action?.icon?.path">
                            <img [src]="newAction?.action?.icon?.path" />
                        </div>
                        <h5>{{ newAction?.action.name }}</h5>
                    </div>
                </div>

                <div class="d-flex mb-4">
                    <div class="btn btn-sm btn-gray mx-auto" (click)="actionCarousel.prev()">Change</div>
                </div>

                <sf-form-involved class="d-block mb-3" name="involved" [view]="view.vars.prototype.children['involved']" [(ngModel)]="newAction.involved"></sf-form-involved>
            </ng-template>
        </ngb-carousel>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-gray me-auto" (click)="modal.dismiss('Cancelled')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="actionCarousel.next()" *ngIf="newAction.action && actionCarousel.activeId === 'action-slide-0'">
            Next<i class="fa fa-arrow-right ms-2"></i>
        </button>
        <button type="button" class="btn btn-success" (click)="saveAction()" *ngIf="newAction.action && actionCarousel.activeId === 'action-slide-1'">
            <i class="fa fa-check me-2"></i>Done
        </button>
    </div>
</ng-template>
