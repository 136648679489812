import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './routes/user/login/login.component';
import { ReloadPageComponent } from './routes/reload-page/reload-page.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { FormRouteComponent } from '@hutsix/ngxh6';

const routes: Routes = [
    { path: 'reload', component: ReloadPageComponent },
    { path: 'login', component: LoginComponent },

    { path: 'form/:slug', canActivate: [AuthGuardService], component: FormRouteComponent },
    { path: 'form/:slug/:id', canActivate: [AuthGuardService], component: FormRouteComponent },

    {
        path: '',
        canActivate: [AuthGuardService],
        component: DashboardComponent,
    },
    {
        path: 'macyouth',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_MACYOUTH'] },
        loadChildren: () => import('./modules/macyouth/macyouth.module').then(m => m.MacyouthModule),
    },
    {
        path: 'macsafe',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_MACSAFE'] },
        loadChildren: () => import('./modules/macsafe/macsafe.module').then(m => m.MacsafeModule),
    },
    { path: 'user-admin', loadChildren: () => import('./modules/user-admin/user-admin.module').then(m => m.UserAdminModule) },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
