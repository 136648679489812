<div class="collection-container">
    <div *ngFor="let item of inputValue; let i = index; trackBy: trackByIndex" [@fadeIn]="!!item.__created" [class.dim]="fade === i" class="collection-rows">
        <form #itemForm="ngForm" ngForm [class.removable]="view.vars.allow_delete">
            <sf-form
                name="value"
                [view]="view.children[i] || view.vars.prototype"
                [(ngModel)]="inputValue[i]"
                [renderAll]="true"
                (ngModelChange)="onInputChange()"
                [fields]="['action', 'involved']"
            ></sf-form>

            <sf-form
                class="promis"
                name="value"
                *ngIf="inputValue[i]?.action?.name?.toLowerCase() === 'called police' || inputValue[i]?.action?.name?.toLowerCase() === 'called ambulance'"
                [view]="view.children[i] || view.vars.prototype"
                [(ngModel)]="inputValue[i]"
                [renderAll]="true"
                (ngModelChange)="onInputChange()"
                [fields]="['promisNumber']"
            ></sf-form>

            <div class="collection-remove-wrapper" *ngIf="view.vars.allow_delete">
                <button type="button" class="btn btn-danger collection-remove" (click)="removeItem(i)" (mouseover)="fade = i" (mouseleave)="fade = null">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </form>

        <hr />
    </div>

    <div class="d-flex" *ngIf="view.vars.allow_add">
        <button type="button" class="btn btn-gray mt-1 mb-0" style="width: 100%" (click)="addItem()"><i class="fa fa-plus"></i> {{ view.vars.attr.add_label || 'Add' }}</button>
    </div>
</div>
