<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view" @fadeInOut>
        <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" (ngModelChange)="modelChange.emit($event)"></sf-form>
    </form>
</ng-container>

<ng-template #loadingOrError>
    <ng-container *ngIf="form.error; else loading">
        <ngxh6-api-error [error]="form.error"></ngxh6-api-error>
    </ng-container>

    <ng-template #loading>
        <ngxh6-loading-spinner @fadeInOut></ngxh6-loading-spinner>
    </ng-template>
</ng-template>
