import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { fadeInOut2, fadeInOut } from '../../../../animations';
import { ApiServiceInterface, DefaultFormComponent, FormServiceInterface } from '@hutsix/ngxh6';
import { MacUserService } from '../../../../services/user/mac-user.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent extends DefaultFormComponent {
    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: MacUserService,
        public location: Location,
        public cdRef: ChangeDetectorRef,

        @Inject('ApiService') public api: ApiServiceInterface,
        public toastr: ToastrService,
    ) {
        super(formService, userService, location, cdRef);
    }

    submit(auto?: boolean) {
        if (!this.api.offline.isConnected && !this.formRef.valid) {
            this.toastr.error('Form contains errors', 'Unable to save');
            return;
        }
        super.submit(auto);
    }
}
