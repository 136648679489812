export const role_hierarchy: { [key: string]: string | string[] } = {
  // changes here need to be reflected in backend: security.yaml
  ROLE_ADMIN: ['ROLE_MACYOUTH_MANAGER', 'ROLE_MACSAFE_MANAGER'],

  ROLE_MACYOUTH_MANAGER: ['ROLE_MACYOUTH_STAFF', 'ROLE_MANAGER'],
  ROLE_MACYOUTH_STAFF: 'ROLE_MACYOUTH_LOCAL_STAFF',
  ROLE_MACYOUTH_LOCAL_STAFF: 'ROLE_MACYOUTH',

  ROLE_MACSAFE_MANAGER: ['ROLE_MACSAFE_COORDINATOR', 'ROLE_MANAGER'],
  ROLE_MACSAFE_COORDINATOR: 'ROLE_MACSAFE_LOCAL_STAFF',
  ROLE_MACSAFE_LOCAL_STAFF: ['ROLE_MACSAFE_STAFF_TEAMLEADER', 'ROLE_MACSAFE_STAFF_SCSO', 'ROLE_MACSAFE_STAFF_CSO'],
  ROLE_MACSAFE_STAFF_TEAMLEADER: 'ROLE_MACSAFE',
  ROLE_MACSAFE_STAFF_SCSO: 'ROLE_MACSAFE',
  ROLE_MACSAFE_STAFF_CSO: 'ROLE_MACSAFE',
};
